/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    br: "br"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "„Die Gedanken wie Wolken am Himmel stehen", React.createElement(_components.br), "\n", "Kommen, wann sie wollen, und ziehen weiter", React.createElement(_components.br), "\n", "Nicht jeder ist ein kompromissbereiter", React.createElement(_components.br), "\n", "Laue Brisen die eilende Zeit verwehen"), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, "Vielleicht wird schon sehr bald alles viel leichter", React.createElement(_components.br), "\n", "Eine Gipfelaussicht erhaben farbenfroh", React.createElement(_components.br), "\n", "In jedem Augenblick ein passendes Bonmot", React.createElement(_components.br), "\n", "Jeder Moment ein bisher unerreichter"), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, "Auf dem Eis des Glücks Pirouetten drehen", React.createElement(_components.br), "\n", "Der schmale Pfad am Abhang wird etwas breiter", React.createElement(_components.br), "\n", "Nach dem Sommerregen riecht es wieder heiter", React.createElement(_components.br), "\n", "Es soll Dir fortan immer gut ergehen\""));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
